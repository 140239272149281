// extracted by mini-css-extract-plugin
export var contributorAvatar = "ContributorListing-module--contributor-avatar--2ccb2";
export var contributorAvatarLink = "ContributorListing-module--contributor-avatar-link--b6c96";
export var contributorContributions = "ContributorListing-module--contributor-contributions--717f3";
export var contributorItem = "ContributorListing-module--contributor-item--a2ef1";
export var contributorItemPrimaryContent = "ContributorListing-module--contributor-item-primary-content--2b4a9";
export var contributorName = "ContributorListing-module--contributor-name--2414a";
export var contributorNameLink = "ContributorListing-module--contributor-name-link--324de";
export var contributorRole = "ContributorListing-module--contributor-role--88adc";
export var contributorRolesContainer = "ContributorListing-module--contributor-roles-container--e2491";
export var contributorsContainer = "ContributorListing-module--contributors-container--13d98";