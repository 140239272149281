// extracted by mini-css-extract-plugin
export var button = "project-page-module--button--23582";
export var buttonIcon = "project-page-module--button-icon--55c4c";
export var callToActionButtons = "project-page-module--call-to-action-buttons--6e568";
export var callToActionButtonsContainer = "project-page-module--call-to-action-buttons-container--5f3fb";
export var callToActionCategory = "project-page-module--call-to-action-category--63657";
export var callToActionCategorySpecification = "project-page-module--call-to-action-category-specification--152bb";
export var callToActionDescription = "project-page-module--call-to-action-description--75a84";
export var callToActionSupport = "project-page-module--call-to-action-support--18d47";
export var licenseFootnote = "project-page-module--license-footnote--27627";
export var pageHeadingCTA = "project-page-module--page-heading-CTA--99ac8";
export var primaryContentAside = "project-page-module--primary-content-aside--c5bd9";
export var primaryContentMain = "project-page-module--primary-content-main--f9f52";
export var projectPageIssue = "project-page-module--project-page-issue--f459e";
export var projectPageIssueFooter = "project-page-module--project-page-issue-footer--429b8";
export var projectPageIssueFooterDiscussion = "project-page-module--project-page-issue-footer-discussion--e07e5";
export var projectPageIssueFooterDiscussionCount = "project-page-module--project-page-issue-footer-discussion-count--6cb28";
export var projectPageIssueFooterDiscussionIcon = "project-page-module--project-page-issue-footer-discussion-icon--ec048";
export var projectPageIssueFooterMeta = "project-page-module--project-page-issue-footer-meta--1e33b";
export var projectPageIssueHeader = "project-page-module--project-page-issue-header--825f8";
export var projectPageIssueHeaderIcon = "project-page-module--project-page-issue-header-icon--be435";
export var projectPageIssueHeaderTitle = "project-page-module--project-page-issue-header-title--c7c84";
export var projectPageLayout = "project-page-module--project-page-layout--a12d9";
export var projectScreenshot = "project-page-module--project-screenshot--02292";
export var projectScreenshotLink = "project-page-module--project-screenshot-link--ce7c2";
export var projectScreenshotList = "project-page-module--project-screenshot-list--d212b";
export var projectScreenshotListItem = "project-page-module--project-screenshot-list-item--25f98";
export var projectScreenshotsContainer = "project-page-module--project-screenshots-container--c0c54";
export var reactImages__viewImage = "project-page-module--react-images__view-image--93238";
export var repoStat = "project-page-module--repo-stat--32811";
export var repoStatCount = "project-page-module--repo-stat-count--4be16";
export var repoStatIcon = "project-page-module--repo-stat-icon--a74fe";
export var repoStatLabel = "project-page-module--repo-stat-label--091c7";
export var repoStats = "project-page-module--repo-stats--a8c6b";
export var sidebarTagListTag = "project-page-module--sidebar-tag-list-tag--c7d34";
export var sidebarTags = "project-page-module--sidebar-tags--a925a";
export var videoResponsive = "project-page-module--video-responsive--44f8e";